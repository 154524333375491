import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/rileyuy/Documents/GitHub/ep-mg-website/src/components/Layout/Layout.js";
import { Link } from "gatsby";
import Container from "layout/Container";
import SEO from "layout/SEO";
import Collapsible from "elements/Collapsible";
import ContactUs from "../components/StaticPages/HelpCenter/ContactUs";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Container isCentered mdxType="Container">
      <SEO title="Help Center" mdxType="SEO" />
      <h2>{`Frequently Asked Questions`}</h2>
      <hr />
      <h4>{`Order`}</h4>
      <Collapsible title="How do I order?" mdxType="Collapsible">
        <p>{`You can order your medicine by uploading your prescription and searching for your medicines.`}</p>
        <ol>
          <li parentName="ol">{`On the home page of medgrocer.com, click the “Order Medicines” button.`}</li>
          <li parentName="ol">{`Search and click for the medicine you want.`}</li>
          <li parentName="ol">{`Change the quantity of your order and click Add to cart.`}</li>
          <li parentName="ol">{`Repeat Steps 2 and 3 for any other medicine you want to purchase.`}</li>
          <li parentName="ol">{`Add other attachments, IDs, and coupon codes, if applicable, and checkout.`}</li>
          <li parentName="ol">{`Fill out your information, payment method, and delivery address. Click Next.`}</li>
          <li parentName="ol">{`Read our terms and conditions, tick the box if you agree, and then click Submit.`}</li>
          <li parentName="ol">{`You’ll receive an email summarizing your order.`}</li>
          <li parentName="ol">{`Wait for the confirmation text from our pharmacist to verify your order.`}</li>
          <li parentName="ol">{`After you confirm, we'll deliver your order within one to four days depending on the availability.`}</li>
        </ol>
      </Collapsible>
      <Collapsible title="Can I order in bulk for my company?" mdxType="Collapsible">
        <p>{`Definitely. Email us at `}<a href="mailto:order@medgrocer.com" target="_blank" rel="noopener noreferrer">{` order@medgrocer.com `}</a>{` for inquiries.`}</p>
      </Collapsible>
      <Collapsible title="How do I pay for my order?" mdxType="Collapsible">
        <p>{`You can pay cash on delivery or bank transfer/bank deposit.`}</p>
        <p><strong parentName="p">{`Paying cash on delivery:`}</strong></p>
        <ul>
          <li parentName="ul">{`Order your medicines normally through any of the two ways described above.`}</li>
          <li parentName="ul">{`Upon delivery, simply give your payment to our rider.`}</li>
        </ul>
      </Collapsible>
      <Collapsible title="How would I know if my order went through?" mdxType="Collapsible">
        <p>{`You will receive a confirmation email immediately after ordering. When your order is being processed, our pharmacist will text you to verify your order. If you do not receive a confirmation email or text from MedGrocer within 48 hours, you may contact us `}<Link to="/help-center#contact-us" className="has-text-weight-bold has-text-primary" mdxType="Link">{`here.`}</Link></p>
      </Collapsible>
      <Collapsible title="How long does it take to process my order?" mdxType="Collapsible">
        <p>{`When your order is being processed, our pharmacist will verify your orders via email. We process orders from 9AM-6PM on weekdays. Orders made during that time are processed within the day. Orders made during the weekend or in the evenings are processed on the next working day.`}</p>
        <p>{`For deliveries in Metro Manila, expect your medicines to arrive within one to four days after you confirm via email.`}</p>
      </Collapsible>
      <Collapsible title="Can I modify my order?" mdxType="Collapsible">
        <p>{`To prevent miscommunications and minimize inaccuracies, orders are considered final upon checkout. For any revisions, please cancel your order by replying `}<strong parentName="p">{`CANCEL ORDER`}</strong>{` to the verification email our pharmacist will send you and place your corrected order on medgrocer.com.`}</p>
      </Collapsible>
      <Collapsible title="How do I cancel my order?" mdxType="Collapsible">
        <p>{`You may only cancel your order `}<strong parentName="p">{`if it has not yet been shipped`}</strong>{`. Contact us at 0917 846 1677 to confirm the status of your order, then discuss the details of your order with our team. If your order can be cancelled, you will receive an order cancellation notification.`}</p>
      </Collapsible>
      <Collapsible title="What if I can’t find my medicines?" mdxType="Collapsible">
        <p>{`If you can’t find your medicines, we’ll find it for you. Message us `}<Link to="/help-center#contact-us" className="has-text-weight-bold has-text-primary" mdxType="Link">{`here`}</Link>{`.`}</p>
      </Collapsible>
      <Collapsible title="Can I order for someone else?" mdxType="Collapsible">
        <p>{`Definitely. If you want your medicines to be delivered straight to the person you’re ordering for, input his/her delivery address.`}</p>
      </Collapsible>
      <Collapsible title="Is there a minimum order?" mdxType="Collapsible">
        <p>{`There's no minimum order for delivery.`}</p>
      </Collapsible>
      <h4>{`Prescription`}</h4>
      <Collapsible title="Do I need a prescription to buy medicines?" mdxType="Collapsible">
        <p>{`Yes. Just like any pharmacy, we require a valid prescription that contains the following details: prescription date, patient’s name, prescriber’s name, and prescriber’s license number.`}</p>
        <p>{`Over-the-counter medicines can be ordered without prescription. However, ethical medicines such as antibiotics require a prescription.`}</p>
      </Collapsible>
      <Collapsible title="How do I send my prescription?" mdxType="Collapsible">
        <p>{`You can upload a picture or a scanned copy of your prescription on the website. You can also present the original copy of the prescription to our courier upon delivery of your order.`}</p>
      </Collapsible>
      <Collapsible title="I can’t upload my prescription." mdxType="Collapsible">
        <p>{`If you can’t upload your prescription, please inform our pharmacist upon order confirmation and present your prescription upon delivery of your order.`}</p>
      </Collapsible>
      <h4>{`Delivery`}</h4>
      <Collapsible title="What are the areas covered for delivery? Is there a convenience fee?" mdxType="Collapsible">
        <p>{`We currently offer nationwide delivery. P200 convenience fee applies to Metro Manila deliveries and P500 delivery fee applies to non-Metro Manila deliveries.`}</p>
        <p>{`The convenience fee covers the cost of packaging, pharmacist advice, and delivery.`}</p>
      </Collapsible>
      <Collapsible title="Can I pick-up the medicines in the MedGrocer office?" mdxType="Collapsible">
        <p>{`Our operations are streamlined to give you a hassle-free delivery experience. Please use our delivery service for your convenience.`}</p>
      </Collapsible>
      <Collapsible title="Can you have it delivered to my workplace?" mdxType="Collapsible">
        <p>{`We deliver to home and office addresses. Just indicate the complete delivery address when you order.`}</p>
      </Collapsible>
      <Collapsible title="When will I receive my order?" mdxType="Collapsible">
        <p>{`We process orders from 9AM-6PM on weekdays. After placing your order on the website, our pharmacist will verify your order via email. Your order will be delivered within one to four days after you confirm via email. Orders placed during the weekend or in the evenings are processed on the next working day.`}</p>
      </Collapsible>
      <Collapsible title="What if I'm not at home when the order arrives?" mdxType="Collapsible">
        <p>{`Your representative may present a copy of your ID, prescription (if applicable), other necessary documents (i.e. senior citizen or PWD ID), and payment to receive your order.`}</p>
      </Collapsible>
      <h4>{`Discount and Promos`}</h4>
      <Collapsible title="Do you honor senior citizen and PWD discounts?" mdxType="Collapsible">
        <p>{`Yes. If you are registered as a Senior Citizen or a Person With Disability with the government, you may avail of the SCPWD discount on your order. Please note that the discount only applies to a maximum of a month’s worth of prescription medicines. This applies only to medicines covered by relevant Philippine laws.`}</p>
        <p>{`To avail of the discount, kindly upload your ID on the shopping cart page and enter SCPWD as the coupon code. Present the original copy of the ID upon delivery.`}</p>
      </Collapsible>
      <Collapsible title="How do I avail of a discount or promotion?" mdxType="Collapsible">
        <p>{`Avail of the discount or promotion as you check out on the shopping cart page, where you will find an `}<strong parentName="p">{`apply coupon box`}</strong>{` below the cart subtotal. Enter the coupon code inside the box and click “Submit“.`}</p>
        <p>{`Unless otherwise stated, promotions are only for first-time users, are only for Metro Manila deliveries, and have daily redemption limits.`}</p>
      </Collapsible>
      <Collapsible title="Can I use multiple discounts or promotions in one order?" mdxType="Collapsible">
        <p>{`You may only use one discount or promotion per order; you will only be able to apply one coupon code per transaction. Promotions cannot be used in conjunction with Senior Citizen or PWD discounts.`}</p>
      </Collapsible>
      <Collapsible title="What are MedGrocer's terms and conditions for promos?" mdxType="Collapsible">
        <p>{`Listed below are a few of our terms and conditions covered by MedGrocer promos. To know the full coverage, please read the complete terms and conditions `}<Link to="/terms-and-conditions" className="has-text-weight-bold has-text-primary" mdxType="Link">{`here`}</Link>{`.`}</p>
        <ul>
          <li parentName="ul">{`Promotional Coupons, in the form of codes, may be issued by MedGrocer from time to time. These may include discounts and free products or services which may be used as payment for products purchased from MedGrocer.`}</li>
          <li parentName="ul">{`Promotional Coupons are valid for the specified period stated on them, can only be redeemed a specified number of times, and cannot be used in conjunction with other promotional coupons (i.e. senior, PWD, or other ongoing MedGrocer promos or discount) unless otherwise stated.`}</li>
          <li parentName="ul">{`Products are subject to stock availability, and individual products may be excluded from certain promotions.`}</li>
          <li parentName="ul">{`Unless otherwise stated, the value of the Promotional Coupon only covers the price of the products in the Order. Any additional fees and charges incurred in connection with the Order, including convenience fee and additional taxes, shall be paid by the User.`}</li>
          <li parentName="ul">{`Promotional Coupons are only applicable to first time orders and Metro Manila deliveries unless otherwise stated.`}</li>
          <li parentName="ul">{`Promotional Coupons for prescription medicines are strictly applicable to licensed medicines which require a doctor's prescription to be obtained. This does not include vitamins, food supplements, and over-the-counter medicines. For promotions covering prescription medicines, User has to upload his/her updated prescription.`}</li>
          <li parentName="ul">{`If the credit from the Promotional Coupon is insufficient for the Order, the User shall pay for the difference.`}</li>
        </ul>
      </Collapsible>
      <h4>{`Returns`}</h4>
      <Collapsible title="What is your return policy?" mdxType="Collapsible">
        <p>{`All products sold on MedGrocer are subject to quality checks by our purchasing, storage, assembly, and delivery teams. Please inspect your order upon delivery. If your medicines are damaged upon delivery, you may refuse to accept the medicines.`}</p>
        <p>{`On a case-to-case basis, we can process requests to return or exchange the medicines within seven days upon delivery. Please make sure that you keep your delivery receipt and the medicines you want to return.`}</p>
      </Collapsible>
      <Collapsible title="What medicines will you accept for returns?" mdxType="Collapsible">
        <p>{`We will replace damaged or wrongly dispensed medicines.`}</p>
      </Collapsible>
      <Collapsible title="Can I return an item after seven days?" mdxType="Collapsible">
        <p>{`Any problems encountered seven days after the delivery may not be accommodated under our Return Policy. We highly encourage you to review and inspect your medicines upon delivery so that our courier could immediately facilitate the return, exchange, or refund request.`}</p>
      </Collapsible>
      <Collapsible title="How long is the processing time for returns?" mdxType="Collapsible">
        <p>{`Returns are processed within two to three working days.`}</p>
      </Collapsible>
      <h4>{`Privacy`}</h4>
      <Collapsible title="What information do you share?" mdxType="Collapsible">
        <p>{`Only our pharmacists processing your orders have access to your medical information to ensure your medicines are properly dispensed. If you are an employee of our partner company, your orders are shared with your employers as healthcare provider to process payments. We may use anonymized data for analytics to improve our services or collaborate with partners.`}</p>
      </Collapsible>
      <Collapsible title="How do I contact the data privacy officer?" mdxType="Collapsible">
        <p>{`MedGrocer’s Data Privacy Officer provides support with any data privacy related questions, comments, concerns, or complaints. You may contact MedGrocer’s Data Privacy Officer through the following information:`}</p>
        <p>{`MedGrocer 24F Centuria Medical Makati, Kalayaan cor. Salamanca St., Makati City `}<br />{`
Contact Number: +63 917 846 1677`}<br />{`
Email address: `}<a href="mailto:dpo@medgrocer.com" className="has-text-weight-bold has-text-primary">{`dpo@medgrocer.com`}</a></p>
      </Collapsible>
      <h4>{`Medicine`}</h4>
      <Collapsible title="What is better for my condition, Drug A or Drug B?" mdxType="Collapsible">
        <p>{`To ask for pharmacy advice, you may message us through Facebook.`}</p>
      </Collapsible>
      <Collapsible title="What is the difference between branded and generic medicines?" mdxType="Collapsible">
        <p>{`Branded and generic medicines that are certified by the Food and Drug Administration are essentially the same. They have the same active ingredient in the medicine, but other ingredients that support or complement the active ingredient may differ.`}</p>
      </Collapsible>
      <Collapsible title="Where are the generic medicines manufactured? Are they locally manufactured?" mdxType="Collapsible">
        <p>{`Our medicines are sourced from both international and local manufacturers. All medicines are FDA-approved. You can search for FDA certification for each medicine at fda.gov.ph.`}</p>
      </Collapsible>
      <Collapsible title="How do I know the expiry of the medicines?" mdxType="Collapsible">
        <p>{`The expiration date can be found on the blister pack or banig where the medicines are placed. We also include the expiry of the medicines on your delivery receipt.`}</p>
      </Collapsible>
      <Collapsible title="What is Value Brand?" mdxType="Collapsible">
        <p>{`MedGrocer’s Value Brands are the most cost-effective FDA-approved medicines that are used by leading health insurers, hospitals, and clinics.`}</p>
      </Collapsible>
      <Collapsible title="What are prescription medicines?" mdxType="Collapsible">
        <p>{`Prescription medicines are licensed medicines which `}<strong parentName="p">{`require a doctor's prescription`}</strong>{` to be obtained. This does not include vitamins, food supplements, and over-the-counter medicines.`}</p>
      </Collapsible>
      <Collapsible title="How can I save money?" mdxType="Collapsible">
        <p>{`Try switching to our Value Brands. Please note that you should still seek the advice of your physician. Ask for alternative brands or alternative medicines that you can take.`}</p>
      </Collapsible>
      <h4>{`Regulatory`}</h4>
      <Collapsible title="What is MedGrocer?" mdxType="Collapsible">
        <p>{`MedGrocer is an FDA-licensed online pharmacy. Simply order your medicines online and we’ll deliver them to you. We carry both branded and generic medicines. We carry both prescription and over-the-counter medicines.`}</p>
      </Collapsible>
      <Collapsible title="Are your medicines FDA-certified?" mdxType="Collapsible">
        <p>{`Yes. The Certificate of Product Registration of a particular medicine can be found at the website of the `}<strong parentName="p">{`Food and Drug Administration`}</strong>{`.`}</p>
      </Collapsible>
      <ContactUs mdxType="ContactUs" />
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      